/* eslint-disable no-irregular-whitespace */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Tooltip } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { EllipsedText } from '../../Utils/TextUtils';
import { getRelativeCallingDate, getRelativeCallingTime } from '../../Utils/RelativeTimeCalculator';
import { CallDisconnectedIcon } from '../../Icons/CallHistoryIcons/CallDisconnected';
import { CallInProgressIcon } from '../../Icons/CallHistoryIcons/CallInProgress';
import { CallFailedIcon } from '../../Icons/CallHistoryIcons/CallFailed';
import { CallCompletedIcon } from '../../Icons/CallHistoryIcons/CallCompleted';
import { CallCanceledIcon } from '../../Icons/CallHistoryIcons/CallCanceled';
import { CallAnsweredIcon } from '../../Icons/CallHistoryIcons/CallAnswered';
import { CallUnansweredIcon } from '../../Icons/CallHistoryIcons/CallUnanswered';
import { CallInitiatedIcon } from '../../Icons/CallHistoryIcons/CallInitiated';
import { CallRingingIcon } from '../../Icons/CallHistoryIcons/CallRinging';
import candidateDrawerMessage from '../../Containers/CandidateDrawer/messages';

import './CallHistoryItem.scss';

export function getCallStatusIcon(status) {
  switch (status) {
    case 'Ringing':
      return <CallRingingIcon />;
    case 'Initiated':
      return <CallInitiatedIcon />;
    case 'Completed':
      return <CallCompletedIcon />;
    case 'Answered':
      return <CallAnsweredIcon />;
    case 'InProgress':
      return <CallInProgressIcon />;
    case 'Failed':
      return <CallFailedIcon />;
    case 'Unanswered':
      return <CallUnansweredIcon />;
    case 'Canceled':
      return <CallCanceledIcon />;
    case 'Connected':
      return <CallCompletedIcon />;
    default:
      return <CallDisconnectedIcon />;
  }
}

function CallHistoryItem({
  callDetails,
  createdUser,
  refId,
  personId,
  basicInfoJobGuidToIdMapping,
  jobsBasicInfoById,
  version,
  openCandidateView,
  openJobViewInNewTabCallBack,
  candidate,
}) {
  const { To, From, CreatedTime, AnsweredTime, EndedTime } = callDetails;
  let { ConversationStatus } = callDetails;
  let duration = '';
  const calledByUserName = createdUser?.FirstName ? `${createdUser.FirstName} ${createdUser.LastName}` : '';
  if (ConversationStatus === 'Completed') {
    const secs = moment.utc(EndedTime).diff(moment.utc(AnsweredTime)) / 1000;
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60);
    ConversationStatus = 'Connected';
    duration = `${hours ? ` ${hours} hrs` : ''} ${minutes ? ` ${minutes} min` : ''} ${Math.ceil(secs % 60)} sec`;
  }

  const jobId = basicInfoJobGuidToIdMapping?.[refId];
  const jobDetails = jobsBasicInfoById[jobId];
  const jobTitle = jobDetails?.JobTitle;
  const entityType = jobDetails?.EntityType;
  const isJobAssignedToCurrentUser = jobDetails?.IsJobAssignedToCurrentUser;
  const jobCode = jobDetails?.JobCode || 'N/A';

  const handleCandidateView = e => {
    if (version === 'ats' && jobCode !== 'N/A') openJobViewInNewTabCallBack(jobCode);
    else if (isJobAssignedToCurrentUser) openCandidateView(candidate, jobId, entityType);
    e.stopPropagation();
  };

  return (
    <div className={classNames('call-history-item', { 'call-history-item-all-calls': personId })} role="presentation">
      {!personId ? (
        <Row
          gutter={19}
          className={classNames('call-history-content-top', { 'call-history-content-top-all-calls': personId })}
          type="flex"
          justify="space-between"
        >
          <Col span={5}>
            <div className="conversation-status">{getCallStatusIcon(ConversationStatus)}</div>
            <div className="duration">{duration ? `  ${duration}   ` : null}</div>
          </Col>
          <Col span={5} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className="called-by">
              <EllipsedText text={calledByUserName} maxTextLength={18} />
            </div>
          </Col>
          <Col span={7} style={{ display: 'flex', alignItems: 'center' }}>
            <div className="phone-number">
              <div style={{ display: 'inline-flex', marginBottom: '10px' }}>
                <span style={{ color: '#83878c' }}>To:</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {To}
              </div>
              <div style={{ display: 'inline-flex' }}>
                <span style={{ color: '#83878c' }}>From:</span>
                &nbsp; {From}
              </div>
            </div>
          </Col>
          <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ marginBottom: '10px' }}>{getRelativeCallingDate(CreatedTime)}</div>
            <div className="time-stamp">{getRelativeCallingTime(CreatedTime)}</div>
          </Col>
        </Row>
      ) : (
        <Row
          gutter={15}
          className={classNames('call-history-content-top', { 'call-history-content-top-all-calls': personId })}
          type="flex"
          justify="space-between"
        >
          <Col span={4}>
            <div className="conversation-status">{getCallStatusIcon(ConversationStatus)}</div>
            <div className="duration">{duration ? `  ${duration}   ` : null}</div>
          </Col>
          <Col span={6}>
            <div className="call-history-job-Info">
              {jobTitle}
              <Tooltip
                title={!isJobAssignedToCurrentUser ? `${entityType} is not shared with you` : ''}
                placement="topLeft"
              >
                <div
                  role="presentation"
                  onClick={handleCandidateView}
                  onKeyPress={handleCandidateView}
                  style={!isJobAssignedToCurrentUser ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                >
                  <u>{version === 'ats' ? jobCode : jobId}</u>
                </div>
              </Tooltip>
            </div>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className="called-by">
              <EllipsedText text={calledByUserName} maxTextLength={18} />
            </div>
          </Col>
          <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
            <div className="phone-number">
              <div style={{ display: 'inline-flex', marginBottom: '10px' }}>
                <span style={{ color: '#83878c' }}>To:</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {To}
              </div>
              <div style={{ display: 'inline-flex' }}>
                <span style={{ color: '#83878c' }}>From:</span>
                &nbsp; {From}
              </div>
            </div>
          </Col>
          <Col span={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ marginBottom: '10px' }}>{getRelativeCallingDate(CreatedTime)}</div>
            <div className="time-stamp">{getRelativeCallingTime(CreatedTime)}</div>
          </Col>
        </Row>
      )}
    </div>
  );
}

CallHistoryItem.propTypes = {
  callDetails: PropTypes.shape({
    To: PropTypes.string,
    CreatedTime: PropTypes.string,
    ConversationStatus: PropTypes.string,
    Caller: PropTypes.string,
  }).isRequired,
};

export default CallHistoryItem;
